"use client";

import { useRouter } from "next/navigation";

import { Button } from "@/components/inputs";
import { ErrorPage } from "@/components/template/ErrorPage";

export default function NotFound() {
  const router = useRouter();

  return (
    <ErrorPage
      title="申し訳ございません。"
      message="お探しのページが見つかりませんでした。"
      actions={
        <Button variants="outlined" rounded onClick={() => router.push("/")}>
          ホーム画面に戻る
        </Button>
      }
    />
  );
}
